import React, { useContext,useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import StampItem from './StampItem';
import { Row, Col, Container, Form} from "react-bootstrap";
import { Context } from '../index';
import {fetchItems} from '../http/stampAPI';

// in fact its list with stamp images on main page
const StampList = observer(() => {
  const { stamp } = useContext(Context);

  useEffect(() => {
    fetchItems().then(data => stamp.setItems(data))
  }, []);

  return (
    <Row  className="d-flex">
      {stamp.items.map((item) =>
        <StampItem key={item.id} item={item} />
      )}
    </Row>
  );
});

export default StampList;

/* {stamp.stamps.slice().reverse().map((stamp)=> 
          <StampItem key={stamp.id} stamp={stamp}/>
        )} */


  // useEffect(() => {
  //   const fetchData = async () => {
  //     await stamp.fetchImages();
  //   };
  //   fetchData();
  // }, [stamp]);
