import React from 'react';
// import { MapGenerate } from '../components/MapGenerate';
import { Container } from 'react-bootstrap';
// import Card from 'react-bootstrap/Card';

function MapPage() {

  return (
    <Container>
      <h1>MapPage</h1>
      {/* <Card>
        <Card.Body>
          <div id="map-container"></div>
          <MapGenerate containerId="map-container" />
        </Card.Body>
      </Card> */}
    </Container>
  );
}

export default MapPage;