import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_ROUTE } from '../utils/consts';
import { Button } from 'react-bootstrap';

const ResetFiltersButton = () => {
    const navigate = useNavigate();
    const handleRefreshPage = () => {
        navigate(BASE_ROUTE, { replace: true });
        window.location.reload();
    };
    return (
        <Button className="mb-2 mt-2"
            style={{ width: '305px' }}
            // variant="outline-light"
            
            variant="dark"
            onClick={handleRefreshPage}>
                Сбросить фильтры
        </Button>
    );
};

export default ResetFiltersButton;

// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { BASE_ROUTE } from '../utils/consts';
// import { Button } from 'react-bootstrap';

// const ResetFiltersButton = () => {
//     const navigate = useNavigate();
//     return (
//         <Button className="mb-4"
//             variant="outline-light"
//             onClick={() => navigate(BASE_ROUTE)}>
//             Назад к базе
//         </Button>
//     );
// };

// export default ResetFiltersButton;